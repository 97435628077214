import { useContractFunction, useEthers } from "@usedapp/core"
import { utils, constants } from "ethers"
import { Contract } from "@ethersproject/contracts"
import LPFarm from "../chain-info/NyanRewards.json"

import ZMT from "../chain-info/PoolHelper_0xMT.json"
import brownieConfig from "../brownie-config-json.json"

import helperConfig from "../helper-config.json"
/**
/**
 * Expose { send, state } object to facilitate unstaking the user's tokens from the TokenFarm contract
 */
export const useUnstakeTokens = () => {
  
  const { chainId } = useEthers()



  const networkName = chainId ? helperConfig[chainId] : "ganache"
  
  const zBTC = chainId ? brownieConfig["networks"][networkName]["PoolHelper"] : constants.AddressZero
  const zABI = ZMT.abi
  const zInterface = new utils.Interface(zABI)

  var zContract = new Contract(zBTC, zInterface)



  return useContractFunction(zContract, "WITHDRAW_ALL_ASSETS", {
    transactionName: "Get rewards",
  })
}
