import { useContractCall, useEthers, useBlockMeta } from "@usedapp/core"
import { utils, BigNumber, constants } from "ethers"

import Auctionz from "../chain-info/Auctions.json"

import brownieConfig from "../brownie-config-json.json"

import ZMT from "../chain-info/PoolHelper_0xMT.json"
import helperConfig from "../helper-config.json"
/**
 * Get the staking balance of a certain token by the user in our TokenFarm contract
 * @param address - The contract address of the token
 */
export const PoolStats2 = (address: string): BigNumber | undefined => {
    const { account, chainId } = useEthers()
    //const { abi } = TokenFarm
    //const tokenFarmContractAddress = chainId ? networkMapping[String(chainId)]["TokenFarm"][0] : constants.AddressZero
  
    //const tokenFarmInterface = new utils.Interface(abi)
  
    var t = account
    const networkName = chainId ? helperConfig[chainId] : "ganache"
    const AuctionAddress = chainId ? brownieConfig["networks"][networkName]["auction"] : constants.AddressZero
    //MY STUFF
    const rewardABI = Auctionz.abi
    const AuctionInterface = new utils.Interface(rewardABI)

    const zBTC = chainId ? brownieConfig["networks"][networkName]["PoolHelper"] : constants.AddressZero
    const zABI = ZMT.abi
    const zInterface = new utils.Interface(zABI)



    const [stakingBalance] =
      useContractCall({
        abi: zInterface,
        address: zBTC,
        method: "getUsers_MaxPercent",
        args: [account],
      }) ?? []
      console.log("WHAT", stakingBalance)
    return stakingBalance
  }