import { useEffect, useState } from "react"
import { useContractFunction, useEthers } from "@usedapp/core"
import TokenFarm from "../chain-info/TokenFarm.json"
import Erc20 from "../chain-info/ERC20.json"
import { utils, constants } from "ethers"
import { Contract } from "@ethersproject/contracts"
import networkMapping from "../chain-info/map.json"
import LPFarm from "../chain-info/NyanRewards.json"
import Auctionz from "../chain-info/Auctions.json"
import Auctionz2 from "../chain-info/ArbiForgeAuctions.json"
import ZMT from "../chain-info/PoolHelper_0xMT.json"
import { AuctionStats } from "."

import brownieConfig from "../brownie-config-json.json"

import helperConfig from "../helper-config.json"
/**
 * This hook is a bit messy but exposes a 'send' which makes two transactions.
 * The first transaction is to approve the ERC-20 token transfer on the token's contract.
 * Upon successful approval, a second transaction is initiated to execute the transfer by the TokenFarm contract.
 * The 'state' returned by this hook is the state of the first transaction until that has status "Succeeded".
 * After that it is the state of the second transaction.
 * @param tokenAddress - The token address of the token we wish to stake
 */
export const AdvPools2 = (tokenAddress: string, startingauction: any, MaxFeeInCents: any, MaxFeeInPercent: any ) => {
  const { chainId, account } = useEthers()
  const { abi } = TokenFarm

  const networkName = chainId ? helperConfig[chainId] : "ganache"
  const LPRewardAddress = chainId ? brownieConfig["networks"][networkName]["synethix_LP"] : constants.AddressZero
  const AuctionAddress = chainId ? brownieConfig["networks"][networkName]["auction"] : constants.AddressZero
  const xbtc = chainId ? brownieConfig["networks"][networkName]["xbtc"] : constants.AddressZero
  const zBTC = chainId ? brownieConfig["networks"][networkName]["PoolHelper"] : constants.AddressZero

//MEME ACTUAL USING LP Contracts
const AuctionsABI2 = Auctionz2.abi
const AuctionsABI = Auctionz.abi
const zABI = ZMT.abi
const zInterface = new utils.Interface(zABI)
const AuctionInterface = new utils.Interface(AuctionsABI)
const AuctionInterface2 = new utils.Interface(AuctionsABI2)
console.log("1")

var AuctionContract = new Contract(AuctionAddress, AuctionInterface2)
var zContract = new Contract(zBTC, zInterface)


  const { send: stakeTokensSend, state: stakeTokensState } =
    useContractFunction(zContract, "z_adjust_2", {
      transactionName: "Auction tokens",
    })

  const [amountToStake, setAmountToStake] = useState("0")

  const [state, setState] = useState(false)
  
  const send = (amount: string) => {  
    setAmountToStake(amount)
    return stakeTokensSend(MaxFeeInCents, startingauction)
  }


  return { send, state }
}



